import { useState, useEffect } from 'react';

const useZoomLevel = () => {
    const [isZoomed, setIsZoomed] = useState(false);

    useEffect(() => {
        const checkZoomLevel = () => {
            const zoomLevel = window.devicePixelRatio;
            setIsZoomed(zoomLevel > 1.4);
        };

        checkZoomLevel();
        window.addEventListener('resize', checkZoomLevel);

        return () => {
            window.removeEventListener('resize', checkZoomLevel);
        };
    }, []);

    return isZoomed;
};

export default useZoomLevel;