import React from "react";
import "./brokerage.css";

import AnimatedComponent from "./aniamtion";

export const BrokerageComp = () => {
  return (
    <>
      <AnimatedComponent />

    </>
  );
};
